import { makeStyles } from "@material-ui/core/styles";
// import Background from "../../../../assets/Background.png";

export const authCardStyles = makeStyles((theme) => ({
  authSectionMains: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  gridContainer: {
    minHeight: "100vh",
  },
  leftContent: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  containerRoots: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
  authSectionMain: {
    height: "100%",
    overflow: "auto"
  },
  onboarding:{
    padding: theme.spacing(0,0),
    borderRadius:'10px'
    // maxWidth: 1050,
  },
  containerRoot: {
    padding: theme.spacing(1),
    maxWidth: 500,
    /* [theme.breakpoints.only("lg")]: {
      width: 550,
    }, */
    /* [theme.breakpoints.only("md")]: {
      width: 660,
    }, */
    /* [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    }, */
  },
  authRoot: {
    borderRadius: 10,
    boxShadow: "none"
  },

  logo: {
    maxWidth: 200,
    maxHeight: 150,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 80,
      maxHeight: 40,
    },
  },
  title: {
    fontWeight: 600,
    color: theme.palette.common.white,
  },
}));
